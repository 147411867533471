import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { Fragment, Suspense, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { array, number, shape, string } from 'prop-types';
import { useWindowSize } from '@magento/peregrine/lib/hooks/useWindowSize';
import { useCategoryContent } from '@magento/peregrine/lib/talons/RootComponents/Category';
import { FilterSidebarShimmer } from '@magento/venia-ui/lib/components/FilterSidebar';
import Breadcrumbs from '@magento/venia-ui/lib/components/Breadcrumbs';
import classes from './category.module.css';
import ErrorView from '../../overrides/venia-ui/components/ErrorView';
import { Gallery, ProductsLayout, Pagination, Button } from '../../components';
import { Meta, StoreTitle } from '@magento/venia-ui/lib/components/Head';
import RichContent from '@magento/venia-ui/lib/components/RichContent';
import ProductsListHeader from './productsListHeader';
import SubCategoryBox from './subCategoryBox';
import LoadingIndicator from '@magento/venia-ui/lib/components/LoadingIndicator';
import { useFilterCategories } from './useFilterCategories';
import { GalleryShimmer } from '@magento/venia-ui/lib/components/Gallery';
var FilterModal = React.lazy(function () {
  return import('../../components/FilterModal');
});
var FilterSidebar = React.lazy(function () {
  return import('../../components/FilterSidebar');
});

var CategoryContent = function CategoryContent(_ref) {
  var _klevuProducts$meta, _klevuProducts$meta2, _klevuProducts$meta$t, _klevuProducts$meta3, _subCategories$filter;

  var categoryId = _ref.categoryId,
      data = _ref.data,
      isLoading = _ref.isLoading,
      pageControl = _ref.pageControl,
      sortProps = _ref.sortProps,
      pageSize = _ref.pageSize,
      setPageSize = _ref.setPageSize,
      klevuProducts = _ref.klevuProducts,
      transformedFilters = _ref.transformedFilters,
      klevuSortingOptions = _ref.klevuSortingOptions,
      klevuFilters = _ref.klevuFilters,
      klevuManager = _ref.klevuManager,
      sorting = _ref.sorting,
      setSorting = _ref.setSorting,
      query = _ref.query,
      totalPagesFromData = _ref.totalPagesFromData,
      klevuLoading = _ref.klevuLoading,
      magentoProducts = _ref.magentoProducts,
      pageSizeValues = _ref.pageSizeValues,
      outletPriceRange = _ref.outletPriceRange;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isFiltersHidden = _useState2[0],
      setIsFiltersHidden = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isProductScrolled = _useState4[0],
      setIsProductScrolled = _useState4[1];

  var _useWindowSize = useWindowSize(),
      innerWidth = _useWindowSize.innerWidth;

  var isSmallDevice = innerWidth <= 1024;

  var _sortProps = _slicedToArray(sortProps, 1),
      currentSort = _sortProps[0];

  var history = useHistory();

  var _React$useState = React.useState(function () {
    if (history.action !== 'POP') {
      return null;
    }

    var persistedId = sessionStorage.getItem('scroll-position-product-id-marker-category');
    sessionStorage.removeItem('scroll-position-product-id-marker-category');
    return persistedId ? persistedId : null;
  }),
      _React$useState2 = _slicedToArray(_React$useState, 1),
      productMarkerId = _React$useState2[0];

  var _useFilterCategories = useFilterCategories(),
      laoding = _useFilterCategories.laoding,
      error = _useFilterCategories.error,
      filterCategoryData = _useFilterCategories.data;

  var _useCategoryContent = useCategoryContent({
    categoryId: categoryId,
    data: data,
    pageSize: pageSize
  }),
      categoryName = _useCategoryContent.categoryName,
      categoryDescription = _useCategoryContent.categoryDescription,
      displayMode = _useCategoryContent.displayMode,
      subCategories = _useCategoryContent.subCategories,
      items = _useCategoryContent.items,
      category = _useCategoryContent.category,
      gridCols = _useCategoryContent.gridCols,
      setGridCols = _useCategoryContent.setGridCols;

  var handleProductScroll = function handleProductScroll() {
    setIsProductScrolled(true);
  }; // const shouldRenderSidebarContent = useIsInViewport({
  //   elementRef: sidebarRef
  // });


  var productsCount = klevuProducts && klevuProducts !== null && klevuProducts !== void 0 && klevuProducts.meta && klevuProducts !== null && klevuProducts !== void 0 && (_klevuProducts$meta = klevuProducts.meta) !== null && _klevuProducts$meta !== void 0 && _klevuProducts$meta.totalResultsFound ? klevuProducts === null || klevuProducts === void 0 ? void 0 : (_klevuProducts$meta2 = klevuProducts.meta) === null || _klevuProducts$meta2 === void 0 ? void 0 : _klevuProducts$meta2.totalResultsFound : 0;
  var totalCount = (_klevuProducts$meta$t = klevuProducts === null || klevuProducts === void 0 ? void 0 : (_klevuProducts$meta3 = klevuProducts.meta) === null || _klevuProducts$meta3 === void 0 ? void 0 : _klevuProducts$meta3.totalResultsFound) !== null && _klevuProducts$meta$t !== void 0 ? _klevuProducts$meta$t : 0;

  var toggleFiltersSidebar = function toggleFiltersSidebar() {
    return setIsFiltersHidden(function (prevState) {
      return !prevState;
    });
  };

  var shouldShowFilterButtons = klevuFilters && klevuFilters.length;
  var shouldShowFilterShimmer = klevuFilters === null; // If there are no products we can hide the sort button.

  var shouldShowSortButtons = productsCount && klevuSortingOptions;
  var shouldShowSortShimmer = !productsCount && klevuLoading;
  var filtersModal = shouldShowFilterButtons ? React.createElement(FilterModal // filterCategoryData={filterCategoryData}
  , {
    totalCount: totalCount,
    filterCategoryData: filterCategoryData // filters={filters}
    ,
    categoryId: categoryId,
    filters: transformedFilters,
    klevuFilterManager: klevuManager
  }) : null; // const maybeFilterButtons = shouldShowFilterButtons ? (
  //     <FilterModalOpenButton filters={transformedFilters} />
  // ) : shouldShowFilterShimmer ? (
  //     <FilterModalOpenButtonShimmer />
  // ) : null;

  var sidebar = shouldShowFilterButtons && !isSmallDevice ? React.createElement(FilterSidebar, {
    filterCategoryData: filterCategoryData // filters={filters}
    ,
    categoryId: categoryId,
    filters: transformedFilters,
    klevuFilterManager: klevuManager
  }) : shouldShowFilterShimmer ? React.createElement(FilterSidebarShimmer, null) : null; // TODO add shimmer

  var productsListHeader = shouldShowFilterButtons ? React.createElement(ProductsListHeader, {
    toggleFiltersSidebar: toggleFiltersSidebar,
    isFiltersHidden: isFiltersHidden,
    filters: klevuFilters,
    pageControl: {
      currentPage: pageControl === null || pageControl === void 0 ? void 0 : pageControl.currentPage,
      setPage: pageControl === null || pageControl === void 0 ? void 0 : pageControl.setPage,
      totalPages: totalPagesFromData
    },
    totalCount: totalCount,
    isLoading: klevuLoading,
    sortProps: sortProps,
    klevuSortProps: [sorting, setSorting],
    availableSortMethods: klevuSortingOptions,
    useKlevuSort: true,
    totalPagesFromData: totalPagesFromData,
    klevuFilterManager: klevuManager,
    pageSize: pageSize,
    setPageSize: setPageSize,
    pageSizeValues: pageSizeValues,
    gridCols: gridCols,
    setGridCols: setGridCols
  }) : null;
  var categoryDescriptionElement = categoryDescription ? React.createElement(RichContent, {
    html: categoryDescription
  }) : null;
  var isError = useMemo(function () {
    if (!totalPagesFromData && !isLoading) {// return <ErrorView />;
    }

    return null;
  }, [categoryId, classes.gallery, classes.pagination, isLoading, items, pageControl, totalPagesFromData]);

  var persistScrollPosition = function persistScrollPosition(id) {
    sessionStorage.setItem('scroll-position-product-id-marker-category', id);
  };

  var content = useMemo(function () {
    var gallery = totalPagesFromData ? React.createElement(Gallery, {
      items: magentoProducts,
      outletPriceRange: outletPriceRange,
      isFiltersHidden: isFiltersHidden,
      pageControl: {
        currentPage: pageControl === null || pageControl === void 0 ? void 0 : pageControl.currentPage,
        setPage: pageControl === null || pageControl === void 0 ? void 0 : pageControl.setPage,
        totalPages: totalPagesFromData
      },
      isCollectionBtnAllowed: true,
      pageSize: pageSize,
      klevuManager: klevuManager,
      productMarkerId: productMarkerId,
      persistScrollPosition: persistScrollPosition,
      handleProductScroll: handleProductScroll,
      isProductScrolled: isProductScrolled,
      gridCols: gridCols
    }) : React.createElement(GalleryShimmer, {
      items: magentoProducts
    });

    if (!totalPagesFromData && !isLoading) {
      return React.createElement("p", null, "No items found with your current selection.");
    }

    return React.createElement(Fragment, null, gallery);
  }, [categoryId, classes.gallery, classes.pagination, isLoading, items, pageControl, totalPagesFromData, pageSize, gridCols]);
  var pagination = shouldShowSortButtons ? React.createElement(Pagination, {
    bottomPagination: true,
    pageControl: {
      currentPage: pageControl === null || pageControl === void 0 ? void 0 : pageControl.currentPage,
      setPage: pageControl === null || pageControl === void 0 ? void 0 : pageControl.setPage,
      totalPages: totalPagesFromData
    },
    totalCount: totalPagesFromData,
    pageSize: pageSize,
    setPageSize: setPageSize,
    pageSizeValues: pageSizeValues
  }) : null; // const categoryTitle = categoryName ? categoryName : <Shimmer width={5} />;

  var rightPartStyle;
  var leftPartStyle;

  if (isSmallDevice) {
    rightPartStyle = {
      width: '100%'
    };
    leftPartStyle = {
      display: 'none'
    };
  } else {
    if (isFiltersHidden) {
      rightPartStyle = {
        width: '100%',
        transition: 'width 0.3s ease-out'
      };
      leftPartStyle = {
        visibility: 'hidden',
        width: '0%',
        transition: 'width 0.3s ease-out'
      };
    } else {
      rightPartStyle = {
        width: '85%',
        transition: 'width .03s ease-in'
      };
      leftPartStyle = {
        width: ' 25%',
        marginRight: '20px',
        transition: 'width 0.3s ease-in'
      };
    }
  }

  var subCategoriesGrid = subCategories === null || subCategories === void 0 ? void 0 : (_subCategories$filter = subCategories.filter(function (subcategory) {
    var _subcategory$products;

    return (subcategory === null || subcategory === void 0 ? void 0 : (_subcategory$products = subcategory.products) === null || _subcategory$products === void 0 ? void 0 : _subcategory$products.total_count) > 0;
  })) === null || _subCategories$filter === void 0 ? void 0 : _subCategories$filter.map(function (subcategory, idx) {
    return React.createElement(SubCategoryBox, {
      key: idx,
      subcategory: subcategory
    });
  }); // const categoriesGrid = subCategories?.map((item, idx) => (
  //     <CategoryItem key={idx} item={item} />
  // ));

  if (isLoading || laoding) {
    return React.createElement("div", {
      className: "flex items-center justify-center h-screen"
    }, React.createElement(LoadingIndicator, null, React.createElement(FormattedMessage, {
      id: 'reqModal.loadingText',
      defaultMessage: 'Loading data...'
    })));
  }

  return React.createElement(React.Fragment, null, categoryDescriptionElement, displayMode === 'PAGE' && React.createElement(React.Fragment, null, React.createElement(StoreTitle, null, categoryName), React.createElement(Meta, {
    name: "description",
    content: category !== null && category !== void 0 && category.meta_description ? category === null || category === void 0 ? void 0 : category.meta_description : ''
  }), React.createElement(Meta, {
    name: "title",
    content: category !== null && category !== void 0 && category.meta_title ? category === null || category === void 0 ? void 0 : category.meta_title : categoryName
  }), React.createElement(Meta, {
    name: "keywords",
    content: category !== null && category !== void 0 && category.meta_keywords ? category === null || category === void 0 ? void 0 : category.meta_keywords : ''
  }), React.createElement("div", {
    className: "grid grid-cols-1 md_grid-cols-2 lg_grid-cols-3 gap-4 md_gap-12 my-20 container"
  }, subCategoriesGrid)), displayMode !== 'PAGE' ? isError ? React.createElement(ErrorView, null) : React.createElement(ProductsLayout, null, React.createElement(StoreTitle, null, categoryName), React.createElement(Meta, {
    name: "description",
    content: category !== null && category !== void 0 && category.meta_description ? category === null || category === void 0 ? void 0 : category.meta_description : ''
  }), React.createElement(Meta, {
    name: "title",
    content: category !== null && category !== void 0 && category.meta_title ? category === null || category === void 0 ? void 0 : category.meta_title : categoryName
  }), React.createElement(Meta, {
    name: "keywords",
    content: category !== null && category !== void 0 && category.meta_keywords ? category === null || category === void 0 ? void 0 : category.meta_keywords : ''
  }), React.createElement(Breadcrumbs, {
    categoryId: categoryId,
    classes: {
      root: classes.breadcrumbs
    }
  }), productsListHeader, React.createElement("div", {
    className: "flex"
  }, React.createElement("div", {
    style: leftPartStyle
  }, sidebar), React.createElement("div", {
    style: rightPartStyle,
    className: "relative z-[3]"
  }, content)), React.createElement("div", {
    className: "mb-40 flex justify-end"
  }, pagination), React.createElement(Suspense, {
    fallback: null
  }, filtersModal)) : null);
};

export default CategoryContent;
CategoryContent.propTypes = {
  classes: shape({
    gallery: string,
    pagination: string,
    root: string,
    categoryHeader: string,
    title: string,
    categoryTitle: string,
    sidebar: string,
    categoryContent: string,
    heading: string,
    categoryInfo: string,
    headerButtons: string
  }),
  // sortProps contains the following structure:
  // [{sortDirection: string, sortAttribute: string, sortText: string},
  // React.Dispatch<React.SetStateAction<{sortDirection: string, sortAttribute: string, sortText: string}]
  sortProps: array,
  pageSize: number
}; // const maybeSortContainer = shouldShowSortButtons ? (
//   <SortedByContainer currentSort={currentSort} />
// ) : shouldShowSortShimmer ? (
//   <SortedByContainerShimmer />
// ) : null;
// const categoryDescriptionElement = categoryDescription ? (
//   <RichContent html={categoryDescription} />
// ) : null;